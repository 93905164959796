import moneyFormat from './moneyFormat'

const install = Vue => {
  Vue.directive('money-format', moneyFormat)
}
if (window.Vue) {
  window['v-money-format'] = moneyFormat
  Vue.use(install); // eslint-disable-line
}

moneyFormat.install = install
export default moneyFormat
