import { moneyFormat } from '@/utils/moneyFormat'

let options = {
  precision: 2,
  thousands: ','
}
const money = (el, binding, vnode) => {
  // // 判断是否是input元素
  if (vnode) {
    el = vnode.elm.querySelector('input')
  } else {
    throw new Error('v-money-format requires 1 input')
  }
  const opt = Object.assign({}, options, binding.value ? binding.value : {})
  const regStr = '/^$*+?.|'
  const inRegStr = regStr.includes(opt.thousands) ? (`\\${opt.thousands}`) : opt.thousands
  const thousandsReg = new RegExp(inRegStr, 'g')

  if (!el.isFocus) { //设置初始值 input框为0 去除显示
    if (el.value == 0) {
      el.value = '';
    }else if(el.value != '' || el.value != 0){  //初始化千分位
      el.value = el.value.replace(thousandsReg, '').cut();
    }
  }
  el.onfocus = function () {
    el.isFocus = true
    el.value = el.value.replace(thousandsReg, '')
  }
  el.onblur = function () {
    el.isFocus = false
    if (el.value != '') {
      el.value = el.value.cut();
    }
    if (el.value == 0) {
      el.value = '';
    }
    // el.value = moneyFormat(el.value, opt.precision, opt.thousands)
  }
  el.oninput = function () {
    // const reg = new RegExp('^\\D*(\\d*(?:\\.\\d{0,' + opt.precision + '})?).*$', 'g')
    // el.value = el.value.replace(thousandsReg, '').replace(reg, '$1').replace(/^(0)[^.]/g, '$1')
    // const reg = new RegExp('^\\D*(\\d*(?:\\.\\d{0,' + opt.precision + '})?).*$', 'g')
    el.value = el.value.replace(/[^\d.-]/g, '')
  }
}

export default money